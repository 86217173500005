<template>
    <div class="certificate-detail">
        <topNav :active="pageIndex" :historyActive="$route.query.oldHistory"></topNav>
        <div class="#" style="padding-top: 22px;margin: 0 auto;max-width: 1200px;box-sizing: border-box;">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                <!-- <el-breadcrumb-item to="/PersonalHome">个人中心</el-breadcrumb-item> -->
                <el-breadcrumb-item :to="{ path: '/CertificateList' }">申请证书</el-breadcrumb-item>
                <el-breadcrumb-item>证书详情</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="main">
            <div class="certificate-detail-header">
                <div style="float: left;">
                    <span class="title"> {{ JSON.parse(this.$route.query.certificateData).name }} </span>
                    <span class="year"> 年度：{{ JSON.parse(this.$route.query.certificateData).yearName == null ?
                        JSON.parse(this.$route.query.certificateData).name : JSON.parse(this.$route.query.certificateData).yearName }} </span>
                </div>
                <div style="float: right;">
                    <el-button @click="fastSelect(tableCourses)" type="primary" plain>一键选择学时</el-button>
                </div>
            </div>
            <div class="certificate-detail-list" v-loading="detailListLoading">
                <el-table ref="tableCourse" :data="tableCourses" border highlight-current-row
                    style="width: 100%;box-sizing: border-box;font-size: 16px;text-align: center;font-weight: 100;min-height: 380px;"
                    @selection-change="courseChange" tooltip-effect="dark">
                    <el-table-column type="selection" width="55">
                    </el-table-column>
                    <el-table-column prop="courseName" align="center" label="课程名称">
                    </el-table-column>
                    <el-table-column prop="creditHours" align="center" label="学时" width="100">
                    </el-table-column>
                    <el-table-column align="center" label="讲师" width="140">
                        <template slot-scope="scope">
                            {{ scope.row.teacherName ? scope.row.teacherName : "无" }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="price" align="center" label="金额（元）" width="100">
                    </el-table-column>
                    <el-table-column label="付款状态" align="center" width="125">
                        <template slot-scope="scope">
                            {{ scope.row.oldDataPayStatus == 1 ? "已付款" : "未付款" }}
                            <!-- <el-tag type="success">
                                {{ scope.row.courseTypeName }}
                            </el-tag> -->
                        </template>
                    </el-table-column>
                    <el-table-column label="课程类型" align="center" width="125">
                        <template slot-scope="scope">
                            <el-tag type="success">
                                {{ scope.row.courseTypeName }}
                            </el-tag>
                        </template>
                    </el-table-column>
                </el-table>
                <!--分页组件-->
                <!-- <div style="margin-top: 20px;">
                    <el-pagination background @size-change="sizeChangeHandle" @current-change="currentChangeHandle"
                        :current-page="page.pageIndex" :page-sizes="[10, 20, 50, 100]" :page-size="page.pageSize"
                        :total="page.totalPage" layout="prev, pager, next" style="text-align: center;">
                    </el-pagination>
                </div> -->
            </div>
        </div>
        <div class="footer">
            <div class="footer-left">
                <div style="float: left;margin-right: 30px;">
                    <span>总计：已选择 </span>
                    <span style="font-size: 22px;color: #1e90ff;font-weight: bold;">{{ this.total.selected }}</span>
                    <span> 门课程</span>
                </div>
                <div style="float: left;">
                    <span>含 </span>
                    <span style="font-size: 22px;font-weight: bold;"
                        :style="total.contain == certificateData ? 'color: #1e90ff;' : 'color: red;'">
                        {{ total.contain }}
                    </span>
                    <span> /{{ certificateData }}学时</span>
                </div>
            </div>
            <div class="footer-right">
                <span>应付总额:</span>
                <span style="font-size: 22px;color: red;font-weight: bold;padding: 0px 10px;margin-right: 30px;">￥{{
                    this.total.money }}</span>
                <button @click="placeOrder">确定</button>
                <!-- <button @click="payBad">确定</button> -->
            </div>
        </div>
    </div>
</template>
 
<script>
import topNav from "@/components/nav/nav.vue"
import { httpGet, httpPost, httpNPGet } from "@/utils/httpRequest";

export default {
    name: 'certificateDetail',
    data() {
        return {
            detailListLoading: false,
            pageIndex: 2,
            //分页对象
            page: {
                //当前页
                pageIndex: 1,
                //每页显示数据
                pageSize: 10,
                //总页数
                totalPage: 0,
            },
            tableCourses: [],
            // 勾选数据
            total: {
                selected: 0,
                contain: 0,
                money: 0,
            },
            // 需要课时
            certificateData: 0,
            // 勾选的课程
            creditHoursItems: []
        }
    },
    components: {
        topNav
    },
    // created() {
    //     console.log(JSON.parse(JSON.parse(this.$route.query.certificateData)))
    // },
    methods: {
        // 支付暂未开通
        payBad() {
            this.$alert('支付功能暂未开通', '警告', {
                confirmButtonText: '确定',
                callback: action => {
                    // this.$message({
                    //     type: 'info',
                    //     message: `action: ${action}`
                    // });
                }
            });
        },
        // 快速选择学时
        fastSelect(row) {
            this.detailListLoading = true;
            // console.log(JSON.parse(this.$route.query.certificateData).id)
            httpGet("/app/app-certificate/fast-select",
                {
                    certificateId: JSON.parse(this.$route.query.certificateData).id
                }, (res) => {
                    let list = []
                    for (const index in this.tableCourses) {
                        list.push(this.tableCourses[index].id)
                    }
                    // console.log(list)
                    for (const index in res.data) {
                        // console.log(list.indexOf(res.data[index].id))
                        let i = list.indexOf(res.data[index].id)
                        this.$refs.tableCourse.toggleRowSelection(row[i])
                        // console.log(this.$refs.tableCourse)
                    }
                    // console.log(row)
                    this.detailListLoading = false;
                }, (err) => {
                    // console.log(err.data)
                    this.detailListLoading = false;
                    this.$message.error("学时不满足要求");
                })
        },
        // 获取学时数
        getRequiredHours() {
            if (typeof (JSON.parse(this.$route.query.certificateData).rule) == "object") {
                for (const index in JSON.parse(this.$route.query.certificateData).rule) {
                    this.certificateData += JSON.parse(this.$route.query.certificateData).rule[index].requiredHours
                }
            } else {
                this.certificateData = 14
            }
        },

        // 获取满足要求的课程
        getCourses() {
            // console.log(this.$route.query)
            httpNPGet("/app/app-user-credit-hours/hours-items?courseTypeId=" + JSON.parse(this.$route.query.certificateData).rule[0].courseTypeId, (res) => {
                // console.log(res.data)
                this.tableCourses = res.data
            })
        },
        // 列表更改
        courseChange(val) {
            this.creditHoursItems = val
            // console.log(val)
            this.total.selected = 0
            this.total.contain = 0
            this.total.money = 0
            for (const index in val) {
                // console.log(val[index])
                this.total.selected++
                this.total.contain += val[index].creditHours
                this.total.money += val[index].price
                // console.log(this.total)
            }
        },
        // 提交选择的课程
        placeOrder() {
            let creditHoursItemIds = [];
            for (const key in this.creditHoursItems) {
                // console.log(key, this.creditHoursItems[key]);
                creditHoursItemIds.unshift(this.creditHoursItems[key].id);
            }
            // console.log(this.total.contain == this.certificateData)
            if (this.total.contain == this.certificateData) {
                httpPost("/app/app-order/place-order",
                    {
                        certificateId: JSON.parse(this.$route.query.certificateData).id,
                        creditHoursItemIds: creditHoursItemIds,
                        // note: "",
                        sourceType: 0
                    }, (res) => {
                        // console.log(res.data)
                        //  console.log(JSON.parse(this.$route.query.certificateData))
                            let data = JSON.stringify(res.data)
                        this.$router.push({
                            name: 'Confirmorder',
                            query: {
                                data: data,
                                certificateMsg: this.$route.query.certificateData
                            }
                        })
                        // console.log(res)
                    }, (err) => {
                         
                        this.$message({
                            message: err.msg,
                            type: 'error'
                        })
                        throw err
                    })
            } else if (this.total.contain < this.certificateData) {
                this.$message.error("学时不满足要求,需要选择" + this.certificateData + "个学时才能申请证书")
            } else if (this.total.contain > this.certificateData) {
                this.$alert('您选择的学时超出了所需学时，如果您坚持这样做，多余的学时也将清空', '警告', {
                    showCancelButton: true,
                    confirmButtonText: '继续',
                    cancelButtonText: '取消',
                    callback: action => {
                        httpPost("/app/app-order/place-order",
                            {
                                certificateId: JSON.parse(this.$route.query.certificateData).id,
                                creditHoursItemIds: creditHoursItemIds,
                                // note: "",
                                sourceType: 0
                            }, (res) => {
                                this.$message({
                                    type: 'success',
                                    message: ''
                                });
                            }, (err) => {
                                 
                                throw err
                            })
                    }
                });
            }

        },
        // 每页数
        sizeChangeHandle(val) {
            this.page.pageSize = val;
            this.page.pageIndex = 1;
        },
        // 当前页
        currentChangeHandle(val) {
            this.page.pageIndex = val;
            this.getCourses();
        },
    },
    mounted() {
        this.getCourses();
        this.getRequiredHours();

    }
}
</script>
 
<style lang="scss" scoped>
.certificate-detail {
    .main {
        width: 1200px;
        margin: 20px auto 80px;

        .certificate-detail-header {
            background-color: #fff;
            padding: 30px;
            text-align: left;
            height: 30px;

            .title {
                font-size: 22px;
                margin-right: 20px;
            }

            .year {
                font-size: 14px;
            }
        }

        .certificate-detail-list {
            margin-top: 30px;
            background-color: #fff;
            padding: 30px 30px 10px 30px;
        }
    }

    .footer {
        padding-top: 40px;
        box-sizing: border-box;
        bottom: 0px;
        box-shadow: 0 -1px 21px rgba(0, 0, 0, 0.12);
        position: fixed;
        width: 100%;
        background-color: #fff;
        padding: 25px 12% 15px 12%;
        font-size: 16px;
        color: rgb(58, 58, 58);
        z-index: 1;

        div {
            flex: 1;
        }

        .footer-left {
            float: left;
            line-height: 40px;
        }

        .footer-right {
            float: right;

            button {
                width: 100px;
                height: 40px;
                font-size: 18px;
                background-color: #1e90ff;
                color: #fff;
                border: none;
                border-radius: 5px;
                outline: none;
                cursor: pointer;
            }
        }
    }
}
</style>